import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyA_U83NrvQtRGnXODW27lwP2FFdd1m490s",
    authDomain: "tensorboxcode.firebaseapp.com",
    projectId: "tensorboxcode",
    storageBucket: "tensorboxcode.appspot.com",
    messagingSenderId: "25905087292",
    appId: "1:25905087292:web:6aff9364497ec5eac69439",
    measurementId: "G-VC0K58NNRW"
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);


// firebaseAuth.onAuthStateChanged((user) => {
//     // console.log("Auth state changed!");
//     if (user) {
//       // console.log('setting user id');
//       // User is signed in, see docs for a list of available properties
//       // https://firebase.google.com/docs/reference/js/firebase.User
//       firebaseAnalytics.setUserId(user.uid);
//     } else {
//       // console.log("user signed out");
//     }
//   });

export {
    firebaseAuth,
    firebaseFirestore,
    firebaseAnalytics
}