import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import MenuNavigation from './components/Menu';
import reportWebVitals from './reportWebVitals';
import CircleLoader from "react-spinners/CircleLoader";
import FooterBottom from './components/Footer';
import { Container } from 'semantic-ui-react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

const App = React.lazy(()=>import('./App'));
const Account = React.lazy(()=>import('./login/Account'));
const Login = React.lazy(()=>import('./login/Login'));
const PrivacyPolicy = React.lazy(()=> import('./legal/PrivacyPolicy'));
const TermsOfUse  = React.lazy(()=> import('./legal/TermsOfUse'));
const Telemetry = React.lazy(()=> import('./legal/Telemetry'));
const HowToUse = React.lazy(()=> import('./HowToUse'));


ReactDOM.render(
  <React.Suspense fallback={
                  <div style={{display:"flex", position:"absolute", top:0, bottom:0, right:0, left:0,}}>
                    <div style={{display: "flex", flexDirection: "row", margin: "auto"}}>
                      <div>
                        <CircleLoader style={{marginLeft: "auto", marginRight: "auto"}} loading={true} size={80} color="#6C63FF" speedMultiplier={1.5} />
                      </div>
                      <div style={{fontWeight: "bold", marginTop: 80, marginLeft: 15}} id="fonts">Loading...</div>
                    </div>
                  </div>
                  }>
      <Router>
        <div className="content">
            <MenuNavigation />
            <Routes>
              {/* <Route path="/pricing">
                <div className="padded-page">
                  <Upgrade />
                </div>  
              </Route> */}
              <Route path="/telemetry" element={
                <div>
                  <Telemetry />
                </div>
              } />
              <Route path="/privacy" element={
                <div>
                  <PrivacyPolicy />
                </div>
              } />
              <Route path="/how-to-use" element={
                <div>
                  <HowToUse />
                </div>
              } />
              <Route path="/terms" element={
                <div>
                  <TermsOfUse />
                </div>
              } />
              <Route path="/account" element={<div>
                  <Account />
                </div>} />
              <Route path="/login" element={<div><Login/></div>} />
              <Route path="/" element ={
                <App />
              } />
            </Routes>
        </div>
        <FooterBottom/>
        {/* <footer>
          <Container inverted style={{color: "#ACACAC", fontSize: "17px", fontWeight: "400", height: 50, textAlign: "center"}}>
            <span style={{color: "white"}}>Made with ❤️ in 🌉</span> &nbsp; | &nbsp;
            <Link to='/terms' target="_blank" style={{color: "inherit"}}>Terms &amp; Conditions</Link> &nbsp; | &nbsp;
            <Link to='/telemetry' target="_blank" style={{color: "inherit"}}>Privacy Policy</Link>
          </Container>
        </footer> */}
      </Router>
  </React.Suspense>,
document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
