import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import StickyFooter from 'react-sticky-footer';
import './css/Common.css'
import './css/Footer.css'
import { saveAs } from 'file-saver';
import { firebaseAnalytics, firebaseAuth, firebaseFirestore } from '../environment/config';
import { Popup, Button, Image, Segment, Container, Header} from 'semantic-ui-react'

const UTTERLY_UNINSTALL_URL = "https://www.utterly.app/UTTERLY_UNINSTALL_README"
const UTTERLY_UNINSTALL_FILENAME = "UTTERLY_UNINSTALL_README"

class FooterBottom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {} 
  }

  render() {
    const style = {
      borderRadius: 0,
      padding: '2em',
      cursor: 'pointer'
    }

    return (
      // <div style={{ bottom: "0", width: "100%", height: "2.5rem" }}>
      //     <StickyFooter
      //     stickyStyles={{
      //     }}>
      //         <div style={{boxShadow: '0 -1px 1px 0 rgba(0, 0, 0, 0.09)'}}>
      //         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', flexWrap: 'wrap', paddingTop: '10px'}}>
      //             <div id="fonts" style={{margin: '10px'}}><Link to='/terms' style={{ color: 'rgba(0,0,0,.5)' }}>Terms & Conditions</Link></div>
      //             <div id="fonts" style={{margin: '10px'}}><Link to='/privacy' style={{ color: 'rgba(0,0,0,.5)' }}>Privacy Policy</Link></div>
      //         </div>
      //         <div id="fonts" className="text-center" style={{paddingBottom: '3px'}}>
      //             © Relu 2020
      //         </div>
      //         </div>
      //     </StickyFooter>
      // </div>
      <footer style={{backgroundColor: "rgba(0,0,0,.87)", bottom: "0", width: "100%"}}>
        <div inverted style={{color: "#ACACAC", fontSize: "17px", fontWeight: "400", height: 50, textAlign: "center", paddingTop: "1rem", paddingBottom: "3rem"}}>
            <span style={{color: "white"}}>Made with ❤️ in 🌉</span> &nbsp; | &nbsp;
            <Link to='/terms' target="_blank" style={{color: "inherit"}}>Terms &amp; Conditions</Link> &nbsp; | &nbsp;
            <Link to='/telemetry' target="_blank" style={{color: "inherit"}}>Privacy Policy</Link>
        </div>
        {/* <Container inverted style={{color: "#ACACAC", fontSize: "17px", fontWeight: "400", height: 50, textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem"}}>
            <p inverted>
                <a href="/login">Try for free for 5 days</a>
            </p>
        </Container> */}
      </footer>
    //   <div className="footer-clean">
    //           <footer>
    //               <div className="container">
    //                   <div className="row justify-content-center">
    //                       {/* <div className="col-sm-4 col-md-3 item">
    //                           <h3>FAQ</h3>
    //                           <ul>
    //                               <li><Link to='/how-to-use' target="">Utterly Setup</Link></li>
    //                               <li><a href="https://discord.gg/KEebD5XJRg" target="_blank">Support &amp; Community</a></li>
    //                               <li>
    //                               <Popup 
    //                               on='click'
    //                               trigger={
    //                                 <a style={{cursor: "pointer"}} href="mailto:support@utterly.app">Contact Us</a>
    //                               }
    //                               content='You can also ask us on Discord ("Support and Community" tab on top)'
    //                               style={style}
    //                               wide
    //                               position = "bottom right"
    //                               />
    //                               </li>
    //                           </ul>
    //                       </div> */}
    //                       <div className="col-sm-4 col-md-3 item">
    //                           <h3>Privacy</h3>
    //                           <ul>
    //                               <li><Link to='/terms' target="_blank">Terms &amp; Conditions</Link></li>
    //                               <li><Link to='/privacy' target="_blank">Privacy Policy</Link></li>
    //                           </ul>
    //                       </div>
    //                       {/* <div className="col-sm-4 col-md-3 item">
    //                           <h3>Utterly Apps</h3>
    //                           <ul>
    //                               <li><a href="https://getutterly.com" target="_blank">Utterly Pronunciation App</a></li>
    //                           </ul>
    //                       </div> */}
    //                       {/* <div className="col-lg-3 item social">  
    //                         <a href="https://twitter.com/TeamUtterly" target="_blank"><Image src="twitter.png" /></a>
    //                         <a href="https://www.producthunt.com/posts/utterly" target="_blank"><Image src="product-hunt.png" /></a>
    //                         <a href="https://www.linkedin.com/company/utterly-com" target="_blank"><Image src="linkedin.png" /></a>
    //                         <a href="https://www.facebook.com/getutterly/" target="_blank"><Image src="facebook.png" /></a>
    //                       </div> */}
    //                   </div>
    //               </div>
    //           </footer>
    //       </div>
    );
  }
}

export default FooterBottom;