import React, {useState, Component } from 'react';
import './css/Menu.css'
import { firebaseAnalytics, firebaseAuth, firebaseFirestore } from '../environment/config';
import { Image, Container, Menu, Input, Header, Popup, Button, Dropdown, Message, Icon, Loader } from 'semantic-ui-react'
import { getAuth, signInAnonymously, signInWithPopup, GithubAuthProvider } from "firebase/auth";

class MenuNavigation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isVideoOpen: false,
            email: null,
            activeItem: "plugin",
            isSignedIn: false, 
            isEmailSent: false,
            downloadSuccess: null,
            downloadError: null,
            signedUserEmail: null,
            hasLoaded: false,
            elevate: "Download Elevate"
        } 
    }
     
      async componentDidMount() {
        this.unregisterAuthObserver = firebaseAuth.onAuthStateChanged(async (user) => {
            if (!user) {
                // console.log("> user is NOT signed in - signing in anonymously")
                // const auth = getAuth();
                
                // await signInAnonymously(auth)
                // .then(() => {
                //     console.log('User signed in anonymously');
                // })
                // .catch(error => {
                //     if (error.code === 'auth/operation-not-allowed') {
                //         console.log('Enable anonymous in your firebase console.');
                //     }
                // });
            } else {
                console.log("> user is signed in - just listening for changes")
                if (!user.isAnonymous && user.email !== null) 
                {
                    // console.log(user)
                    this.setState({signedUserEmail: user.email})
                    // let signInMethods = await firebaseAuth.fetchSignInMethodsForEmail(user.email)
                //     .then(() => {
               
                //     })
                //     .catch((error) => {})
                // }
                    // if (signInMethods.indexOf(
                    //     firebaseAuth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1 || user.emailVerified) 
                    // {
                    //     this.setState({signedUserEmail: user.email})
                    // }
                }
            }
            this.setState({hasLoaded: true})
        })
      }

      signOut = () =>
        {
            firebaseAuth.signOut().then(() => {
                // firebaseAnalytics.logEvent('sign-out-from-menu')
                window.location.reload(); 
            // Sign-out successful.
            }).catch((error) => {
                alert(error)
            })
        }

      render() {
          return (
              <>
                {/* </><div className="non-mobile-menu" style={{ backgroundColor: "#1b1c1d"}}> */}
                <div style={{ backgroundColor: "#1b1c1d"}}>
                    <Menu id="menu-custom" secondary style={{height: 100}}>
                        <Menu.Item
                        href="/"
                        >
                        <Image src="Logo.svg" style={{height:40, marginTop:15, marginBottom:15, marginRight: 10, cursor: "pointer"}} /> &nbsp;
                        <b style={{color: "white", fontSize: 18}}>TensorBox</b>
                        </Menu.Item>
                        <Menu.Menu id="fonts" style={{marginLeft: "auto", marginRight: "auto", fontWeight: 600}}>
                        {/* <Menu.Item
                            id="fonts"
                            href="/pricing"
                            style={{fontWeight: "bold", color: "white"}}
                            name='Pricing'
                            onClick={()=>{firebaseAnalytics.logEvent('train-from-menu')}}
                        /> */}
                        {/* <Menu.Item
                            id="fonts"
                            href="/blog"
                            style={{fontWeight: "bold", color: "white"}}
                            name='Blog'
                            onClick={()=>{firebaseAnalytics.logEvent('blog-from-menu')}} /> */}
                        {/* <Dropdown item text='Resources' style={{fontWeight: "bold", color: "white"}}>
                            <Dropdown.Menu>
                                <Dropdown.Header>No-Code</Dropdown.Header>
                                <Dropdown.Item href="/no_code_model_tutorial">Run a model</Dropdown.Item>
                                <Dropdown.Item href="/fine_tuning_tutorial">Train a model</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Header>Developers</Dropdown.Header>
                                <Dropdown.Item href="/developer_model_tutorial">Run a model via API</Dropdown.Item>
                                <Dropdown.Item href="/fine_tuning_tutorial">Train a model</Dropdown.Item>
                                <Dropdown.Item href="/documentation">API documentation</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        {/* <Menu.Item
                            id="fonts"
                            href="https://discord.gg/MQBhvWEeZj"
                            style={{fontWeight: "bold", color: "white"}}
                            name='Community'
                            onClick={()=>{firebaseAnalytics.logEvent('community-from-menu')}} /> */}
                        {/* <Menu.Item
                            id="fonts"
                            style={{fontWeight: "bold"}}
                            href="https://discord.gg/MQBhvWEeZj"
                            target="_blank"
                            onClick={()=>{firebaseAnalytics.logEvent('community-menu')}}
                            name='Community'
                            content='Community'
                        />
                        {/* <Dropdown style={{fontWeight: "bold"}} id="fonts" item text='Other Products'>
                            <Dropdown.Menu id="fonts">
                            <Dropdown.Item onClick={()=>{firebaseAnalytics.logEvent('getutterly-click-from-menu')}} style={{fontWeight: "bold"}} href="https://getutterly.com" target="_blank" id="fonts">Visit Utterly pronunciation app</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        </Menu.Menu>
                        <Menu.Menu position="right">
                                <Menu.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    name='Support'  
                                >
                                    <a href="mailto:support@gettensorbox.com" style={{color: "white"}} id="fonts">Support</a>
                                </Menu.Item>
                                <Menu.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    name='Community'  
                                >
                                    <a href="https://discord.gg/jNr7YB7NKk" style={{color: "white"}} id="fonts">Community</a>
                                </Menu.Item>
                            {
                                (this.state.hasLoaded === true) 
                                ?
                                ((this.state.signedUserEmail === null)
                                ?
                                <Menu.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    name='Login'  
                                >
                                    {/* <button className="circle-login-button" style={{backgroundColor: "#0ead69"}} onClick={()=>{firebaseAnalytics.logEvent('contact-us-menu');}}><a href="mailto:malika@tensorbox.ai" style={{color: "white"}}>SCHEDULE A DEMO</a></button> */}
                                    {/* <button className="circle-login-button" style={{backgroundColor: "#0ead69"}} onClick={()=>{firebaseAnalytics.logEvent('contact-us-menu');}}></button> */} 
                                    <a href="/login" style={{color: "white"}} id="fonts">Login</a>
                                    {/* <Button onClick={()=>{
                                        const provider = new GithubAuthProvider();
                                        const auth = getAuth();
                                        signInWithPopup(auth, provider)
                                          .then((result) => {
                                            // This gives you a GitHub Access Token. You can use it to access the GitHub API.
                                            const credential = GithubAuthProvider.credentialFromResult(result);
                                            const token = credential.accessToken;
                                        
                                            // The signed-in user info.
                                            const user = result.user;
                                            // ...
                                          }).catch((error) => {
                                            // Handle Errors here.
                                            const errorCode = error.code;
                                            const errorMessage = error.message;
                                            // The email of the user's account used.
                                            const email = error.email;
                                            // The AuthCredential type that was used.
                                            const credential = GithubAuthProvider.credentialFromError(error);
                                            // ...
                                          });
                                        
                                    }} inverted><Icon name="github" size='large' color='white' /> Sign in with Github</Button> */}
                                </Menu.Item>
                                :
                                <Dropdown 
                                item 
                                text='Account'
                                id="fonts"
                                style={{fontWeight: "bold", color: "white"}}
                                >
                                    <Dropdown.Menu style={{backgroundColor: "#1b1c1d"}}>
                                        <Dropdown.Item style={{textAlign: "left"}} disabled><span style={{fontWeight: 600, color: "white"}}><Icon name='user circle' />{this.state.signedUserEmail}</span></Dropdown.Item>
                                        {/* <Dropdown.Item>Refer a Friend</Dropdown.Item> */}
                                        {/* <Dropdown.Item style={{textAlign: "left"}} onClick={()=>{firebaseAnalytics.logEvent('menu: click utterly elevate upgrade')}} href="/upgrade"><Icon name='star' />Upgrade to Elevate (Pro)</Dropdown.Item> */}
                                        {/* <Dropdown.Item style={{textAlign: "left"}} onClick={()=>{firebaseAnalytics.logEvent('menu: click dashboard')}} href="/dashboard"><span style={{fontWeight: '400'}}>Dashboard</span></Dropdown.Item> */}
                                        <Dropdown.Item style={{textAlign: "left"}} onClick={()=>{firebaseAnalytics.logEvent('menu: click settings')}} href="/account"><span style={{color: "white"}}>Settings & API key</span></Dropdown.Item>
                                        <Dropdown.Item onClick={this.signOut} style={{textAlign: "left"}}><span style={{color: 'white'}}>Sign Out</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>)
                                :
                                // <Spinner animation="border" role="status" style={{alignSelf: "center"}}>
                                //     {/* <span className="sr-only">Loading...</span> */}
                                // </Spinner>  
                                <Loader />
                                // <Menu.Item
                                //     href="/account"
                                //     id="fonts"
                                //     style={{fontWeight: "bold"}}
                                //     name='Account'  
                                //     onClick={()=>{firebaseAnalytics.logEvent('account-from-menu')}} 
                                // >
                                //     {/* User: <a href="/account">  {this.state.signedUserEmail}</a> */}
                                // </Menu.Item>
                            }
                        </Menu.Menu>
                    </Menu>
                    </div>
                {/* <div className="mobile-menu" style={{ backgroundColor: "#2f2e41" }}>
                    <Menu secondary>
                        <Menu.Item
                            href="/"
                            > */}
                            {/* <Image src="logo_cropped_bright.svg" style={{height:40, marginTop:15, marginBottom:15, cursor: "pointer"}} /> <b style={{color: "#2E2E35", fontFamily: "Gilroy"}}>tensorbox</b> */}
                            {/* <Image src="logo_transparent_cropped.png" style={{height:40, marginTop:15, marginBottom:15, cursor: "pointer"}} />                           
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            <Dropdown
                                item
                                icon ='bars'
                                >
                                <Dropdown.Menu> */}
                                    {/* <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/"
                                    onClick={()=>{firebaseAnalytics.logEvent('home-menu-mobile-click')}}
                                    text='Home'
                                    /> */}
                                    {/* <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/docs"
                                    onClick={()=>{firebaseAnalytics.logEvent('docs-clicked-from-mobile-menu')}}
                                    text='Docs'
                                    /> */}
                                    {/* <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="https://discord.gg/KEebD5XJRg"
                                    target="_blank"
                                    onClick={()=>{firebaseAnalytics.logEvent('community-menu-mobile')}}
                                    text='Community & Support'
                                    />
                                    <Dropdown.Item
                                        id="fonts"
                                        style={{fontWeight: "bold"}}
                                        href="/upgrade"
                                        onClick={()=>{firebaseAnalytics.logEvent('mobile-menu: click utterly elevate upgrade')}}
                                        text='Utterly Elevate'
                                    /> */}
                                    {/* {
                                    (this.state.signedUserEmail === null)
                                    ?
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    // onClick={this.signOut}
                                    href="/login"
                                    text='Login'
                                    />
                                    :
                                    <> */}
                                    {/* <Dropdown.Item 
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/fine_tune"
                                    text='Dashboard'
                                    onClick={()=>{firebaseAnalytics.logEvent('dashboard-from-menu-mobile')}}
                                    /> */}
                                    {/* <Dropdown.Item 
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/train"
                                    text='Train'
                                    onClick={()=>{firebaseAnalytics.logEvent('train-from-menu-mobile')}}
                                    />
                                    <Dropdown.Item 
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    href="/run"
                                    text='Run'
                                    onClick={()=>{firebaseAnalytics.logEvent('run-from-menu-mobile')}}
                                    />
                                    <Dropdown.Item
                                    id="fonts"
                                    style={{fontWeight: "bold"}}
                                    onClick={this.signOut}
                                    text='Sign Out'
                                    />
                                    </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu>
                </div> */}
              </>
          )
      }
}

export default MenuNavigation